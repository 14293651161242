import { get, del, patch, post } from "./api.service";

const SubscriptionService = {
    findInvoices: (params) => get("/admin/subscription/invoices", params),
    payInvoice: (params) => post("/admin/subscription/invoice/pay", params),
    getSubscription: () => get("/admin/subscription", "", true),

    updateSubscription: (params) => patch("/admin/subscription", params),

    startSubscription: (params) => post("/admin/subscription/start", params),

    getPaymentMethods: () => get("/admin/subscription/paymentmethod/list"),

    addPaymentMethod: () => post("/admin/subscription/paymentmethod"),

    deletePaymentMethod: (paymentMethodId) => del("/admin/subscription/paymentmethod", { paymentMethodId }),

    setDefaultPaymentMethod: (paymentMethodId) =>
        post("/admin/subscription/paymentmethod/default", { paymentMethodId }),

    getSeats: () => get("/public/registration/seats"),

    getPublishableKey: () => get("/public/util/stripe-publishable-key"),

    signUp: (params) => post("/public/registration/sign-up", params),

    signUpFree: (params) => post("/public/registration/sign-up-free", params),
};

export { SubscriptionService };
